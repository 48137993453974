@import "custom";
@import "~bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
[v-cloak] {
  display: none;
}
html {
  font-size: 15px;
  font-feature-settings: "palt";
  letter-spacing: 0.08em;
}
.bodybg {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
  display: none;
}
#app {
  position: relative;
  background-image: url("../img/paperall.png"), url("../img/bg.jpg");
  background-position: top center, top center;
  background-size: 90%, 100%;
  background-repeat: repeat-y, repeat-y;
  @include media-breakpoint-up(sm) {
    background-size: 90%, 100%;
  }
  @include media-breakpoint-up(lg) {
    background-size: 1120px, 100%;
  }
  .paper {
    width: 65%;
    margin: 0 auto;
    background: url("../img/bg_contents.png") top center repeat-y;
    background-size: contain;
    position: relative;
    z-index: 2;
    padding-bottom: 60px;
    @include media-breakpoint-up(lg) {
      width: 700px;
      background-size: auto;
    }
  }
  .bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    // height: 100%;
    top: 0;
    // background: url("../img/bg.jpg") top center repeat-y;
    background-size: 100%;
    .inner {
      max-width: 96%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        flex-basis: 50%;
        margin-bottom: 7.5%;
        &:nth-child(even) {
          text-align: right;
        }
        img {
          max-width: 20vw;
          height: auto;
          @include media-breakpoint-up(lg) {
            max-width: none;
          }
        }
      }
      @include media-breakpoint-up(lg) {
        max-width: 1140px;
      }
    }
  }
}
img {
  max-width: 100%;
  height: auto;
}
@import "components/header";
// @import "components/nav";
@import "components/article";
// @import "components/pagination";
// @import "components/footer";
@import "u";
