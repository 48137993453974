#header {
  position: sticky;
  top: 0;
  z-index: $zindex-sticky;
  padding: 30px 15px;
  // background: url("../img/bg_header.png") top center no-repeat;
  // background-size: contain;
  @include media-breakpoint-up(sm) {
    padding: 60px 60px 45px;
  }
  #logo {
    text-align: center;
    img {
      max-width: 160px;
      height: auto;
      @include media-breakpoint-up(sm) {
        max-width: 200px;
      }
      @include media-breakpoint-up(lg) {
        max-width: 265px;
      }
    }
  }
  #gnav {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-family: $font_min;
    li {
      padding: 5px;
      flex-basis: 50%;
      @include media-breakpoint-up(lg) {
        padding: 10px;
      }
      a {
        // width: 200px;
        display: block;
        opacity: 0.75;
        font-size: 75%;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
