.articleWrap {
  .article {
    $cms_space: 1rem;
    .cmsHeader {
      padding: $cms_space;
      @include media-breakpoint-up(sm) {
        padding: $cms_space * 2 $cms_space * 2 $cms_space * 1.5;
      }
      .date {
        font-size: 12px;
        line-height: 1.25;
        margin-bottom: 20px;
      }
      .title {
        font-size: 21px;
        font-weight: bold;
        line-height: 1.25;
        margin: 0;
        font-family: $font_min;
      }
    }
    .cms {
      padding: 0 $cms_space;
      @include media-breakpoint-up(sm) {
        padding: 0 $cms_space * 2;
      }
      p {
        margin-bottom: $cms_space;
        line-height: 2;
      }
    }
  }
}
